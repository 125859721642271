<template>
  <Header />
  <div class="page">
    <slot />
  </div>
  <RenderCacheable
    :max-age="86400"
    :cache-key="[getUserLanguage.name, getHash].join('--')"
  >
    <Footer />
  </RenderCacheable>
</template>

<script setup>
import Header from "~~/modules/header/components/Header.vue";
import Footer from "~~/modules/footer/components/Footer.vue";
import {
  subscriptionModal,
  useModalStore,
} from "~~/modules/shared/modals/store/modalStore";
import { useStorageStore } from "~/store/storageStore";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useCategoriesStore } from "~/store/categoriesStore";

const modalStore = useModalStore();
const { isActiveModal } = storeToRefs(modalStore);

const storageStore = useStorageStore();
const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);

onMounted(() => {
  if (isActiveModal.value(subscriptionModal)) {
    modalStore.toggleModal(subscriptionModal);
  }

  //for old users
  if (storageStore.storage.subscriptionModal) {
    storageStore.removeStorageState("subscriptionModal");
  }

  if (
    storageStore.storage.subscriptionViewState?.expiredAt <=
    new Date().getTime()
  ) {
    storageStore.removeStorageState("subscriptionModal");
  }
});
</script>

<style lang="scss">
.show-modal-enter-active,
.show-modal-leave-active {
  transition: opacity 0.3s ease;
}

.show-modal-leave-to,
.show-modal-enter-from {
  opacity: 0;
}

.item-info-enter-from {
  transform: translateY(-10px);
  opacity: 0;
}

.item-info-enter-to {
  transform: translateY(0px);
  transition: all 0.4s ease;
}

.item-info-leave-to {
  transform: translateY(-10px);
  transition: all 0.4s ease;
  opacity: 0;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes arrow-move-forward {
  0% {
    transform: translateX(0%);
  }

  30% {
    transform: translateX(100%);
    opacity: 0;
  }

  70% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.product__container {
  @include flex-container(column, flex-start);
  gap: 32px;

  @extend %shadow;
  background-color: white;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  padding: 24px 16px;

  @include mobile {
    border-radius: 0;
    padding: 16px;
  }
}
</style>
